import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import LightHeader from "../components/headers/lightHeader"
import FormattedParagraphs from "../components/formattedParagraphs"
import Triangle from "../assets/triangle.svg"
import electricGif from "../images/electric.gif"
import NoText from "../assets/no.svg"
import { Link } from "gatsby"

const SomeTriangularThoughtsPage = () => {
  const allPageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: {
            in: ["yellow-bg.png", "cow.png", "somett.png", "tap.png", "no.png"]
          }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 90, maxHeight: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      sanityPage(id: { eq: "e76f4597-b387-5d1c-a77f-b01a8e5b0bb6" }) {
        _rawMainContent
      }
    }
  `)
  const imageArr = allPageData.allFile.edges

  const getImage = (queryEdges, imageName) => {
    const imageObject = queryEdges.find(x => x.node.name === imageName)
    return imageObject.node.childImageSharp.fluid
  }
  const yellowImage = getImage(imageArr, "yellow-bg")
  const cowImage = getImage(imageArr, "cow")
  const someTri = getImage(imageArr, "somett")

  const pageContent = allPageData.sanityPage._rawMainContent

  return (
    <>
      <BackgroundImage
        backgroundColor={`#ffe066`}
        // className="w-screen h-screen bg-fixed"
        style={{
          width: "100%",
          backgroundAttachment: "fixed",
        }}
        fluid={yellowImage}
      >
        <div
          style={{
            minHeight: "3300px",
            overflow: "hidden",
            width: "100vw",
            // backgroundColor: "#eee",
            position: "relative",
          }}
        >
          <LightHeader height={32} />
          <div className={`flex justify-center pt-32`}>
            <Link to="/sttintroduction">
              <Img fluid={someTri} className="w-56" />
            </Link>
          </div>

          <Img
            className="absolute transform scale-110 duration-1000 ease-in-out hover:scale-100 "
            style={{
              mixBlendMode: "multiply",
              width: 15 * 15 + "px",
              top: "57vh",
              left: "77vw",
              position: "fixed",
            }}
            fluid={cowImage}
          />


          <Img
            className="absolute"
            style={{
              mixBlendMode: "multiply",
              width: "885px",
              top: "1271px",
              left: "-128px",
              position: "absolute",
              zIndex: "-50",
            }}
            fluid={getImage(imageArr, "tap")}
          />

          {/* <div
          style={{
            zIndex: "12",
            overflow: "hidden",
            position: "relative",
            width: "100vw",
            height: "2000px",
            border: "1px dotted black",
            backgroundColor: "#eee",
          }}
        > */}
          <Triangle
            style={{
              width: "90vw",
              // width: "1879px",
              top: "938px",
              left: "15vw",
              position: "absolute",
              zIndex: "11",
              overflow: "hidden",
            }}
          />
          {/* </div> */}

          <img
            style={{
              mixBlendMode: "multiply",
              width: "640px",
              top: "2656px",
              left: "26vw",
              position: "absolute",
              zIndex: "-50",
            }}
            src={electricGif}
            alt="gif of molecules"
          />

          <NoText
            style={{
              width: "518px",
              top: "2588px",
              left: "55vw",
              position: "absolute",
              zIndex: "12",
            }}
          />
          {/* </div> */}
          {/* <h1>{JSON.stringify(yellowImage)}</h1> */}
          <div className="px-8">
            <main className="pt-32 container mx-auto max-w-2xl">
              <FormattedParagraphs blocks={pageContent} />
            </main>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}
/* */

export default SomeTriangularThoughtsPage
